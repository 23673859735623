import styled, {keyframes} from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  background-color: ${({backgroundColor}) => backgroundColor};
  padding: 5rem 0;
  margin-top: -12px;
  position: relative;

  .second-parallax {
    position: relative;
    &::after {
      content: '';
      display: block;
      background: white;
      margin: 0 auto;
      height: 5rem;
      bottom: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  position: relative;

  @media (max-width: 1024px) {
    padding: 0 40px;
  }
`;

export const TitlePage = styled.h3`
  color: #525252;
  font-family: 'RENNERBLACK';
  font-size: 4rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 328.44px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 1px;
  white-space: nowrap;
  transform: translateX(-50%) translateY(-80%);
  line-height: 1;

  @media (max-width: 600px) {
    font-size: 3rem;
  }
  @media (max-width: 380px) {
    font-size: 2.6rem;
  }
`;

export const TitlePageSecond = styled.h3`
  color: #525252;
  font-family: 'RENNERBLACK';
  font-size: 4rem;
  font-weight: 400;
  font-style: normal;
  width: 100%;
  background: #525252;
  color: #e0c887;
  letter-spacing: normal;
  line-height: 328.44px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  padding-left: 2.3rem;
  white-space: nowrap;
  text-align: center;
  line-height: 1;
  z-index: 2;

  @media (max-width: 600px) {
    font-size: 3rem;
  }
  @media (max-width: 380px) {
    font-size: 2.6rem;
  }
`;

export const ColorWrapper = styled.div`
  background-color: ${({bgColor}) => bgColor};
  padding: 3rem 0;
  position: relative;
  padding-bottom: 0;
`;

export const ImageWrapper = styled.div`
  background-image: url(${({srcBg}) => srcBg});
  background-size: cover;
  width: 100%;
  height: 62vh;
  margin-top: calc(-5rem - 12px);
  background-position: 85% top;
`;

export const PartnersWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1rem;

  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const PartnerLogo = styled.img`
  height: ${({height}) => (height ? `${height}rem` : '4rem')};
  margin-bottom: 1rem;
`;

export const PartnerTitle = styled.p`
  color: #525252;
  font-family: 'LATOBLACK';
  font-size: 1rem;
  font-weight: 400;
  padding-top: 1rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;
