import React from 'react';
import SEO from '../../components/seo';
import PartnersPage from '../../components/desktop/PartnersPage/PartnersPage';

const About = () => {
  return (
    <>
      <SEO title={'Patronaty i partnerzy'} />
      <PartnersPage bgColor="#E0C887" />
    </>
  );
};

export default About;
